import React, { useContext } from "react";
import Lieferando from "../../assets/images/Lieferando.png";
import flowDark from "../../assets/images/menu.png";
import flowLightPDF from "../../assets/images/JK's Burger Menu.pdf";
import flowDarkPDF from "../../assets/images/JK's Burger Menu.pdf";
import { motion } from "framer-motion";
import { ThemeBgContext } from "../ContextWrapper/ThemeContext";
const FurnitureFlow = () => {
  const { theme } = useContext(ThemeBgContext);
  return (
  //   <div className="p-8 py-16 flex items-center justify-center ">
  //   <img
  //     className=" bg-center bg-no-repeat bg-cover max-w-full max-h-full"
  //     src={theme === "light" ? flowLight : flowDark}
  //     alt="furniture flow"
  //   />
  // </div>
//   <div className="container-fluid mx-auto bg-customOrange ">
//   <div className=" p-8 py-16 flex items-center justify-center">
//   {/* <iframe
//     className="bg-center bg-no-repeat bg-cover max-w-full max-h-full"
//     src={theme === "light" ? flowLightPDF : flowDarkPDF}
//     alt="PDF document"
//     width="100%"
//     height="600px"  // Adjust height as needed
//     title="PDF Viewer"
//   /> */}
// </div>
// </div>
<div className="container-fluid mx-auto bg-customOrange font-russoOne font-bold">
  <div className="p-8 py-16 flex flex-col items-center justify-center">
   
    <h1 className="text-4xl  text-white mb-8">
      Explore Delicious Meals with Our Delivery Partners
    </h1>

    <motion.div
            className=""
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: .5 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
          >
    <div className="bg-white shadow-lg rounded-lg overflow-hidden max-w-sm w-full">
      <img
        className="w-full h-full "
        src={Lieferando}  // Replace with an actual image URL
        alt="Lieferando"
      />
      <div className="p-4 flex flex-col items-center justify-center" >
        {/* <h2 className="text-xl font-semibold text-gray-800">Lieferando</h2> */}
        {/* <p className="text-gray-600 mt-2">
          Enjoy your favorite meals delivered right to your door. Order now with Lieferando!
        </p> */}
        <a target="_blank"
          href="https://www.lieferando.de/speisekarte/jks-burger"  // Link to the Lieferando website
          className="block mt-4 text-white bg-customOrange p-4 border rounded-lg"
        >
          Order Now
        </a>
      </div>
    </div>
    </motion.div>
  </div>
</div>


  );
};

export default FurnitureFlow;
