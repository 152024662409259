import React, { useContext, useState } from "react";
import { tabsData } from "../../DataForPage/dummyData";
import { ThemeBgContext } from "../ContextWrapper/ThemeContext";

const AccordionComponent = () => {
  const { theme } = useContext(ThemeBgContext);
  const [activeTab, setActiveTab] = useState(null); // Track the currently active tab

  // Toggle active tab on click
  const handleToggle = (value) => {
    setActiveTab(activeTab === value ? null : value);
  };

  return (
    <div
      id="features"
      className="container mx-auto p-4 bg-customOrange font-russoOne"
    >
      <div className="w-full p-4">
        {/* Accordion Header */}
        <div className="w-full flex flex-wrap justify-center bg-gray-100 p-2 rounded-lg">
          {tabsData.map(({ label, value, price, desc, img }) => (
            <div key={value} className="w-full mb-2">
              <button
                onClick={() => handleToggle(value)}
                className={`w-full flex-grow sm:flex-grow-0 sm:min-w-[150px] px-2 py-2 text-center rounded-md shadow-md 
                  ${
                    activeTab === value
                      ? "bg-customOrange text-white" // Active state color
                      : "bg-white text-slate-900 hover:bg-gray-200" // Inactive state color
                  }`}
              >
                <p className="font-inter text-sm">
                  {label} <span className="block sm:inline">€{price}</span>
                </p>
              </button>

              {/* Accordion Content */}
              {activeTab === value && (
                <div className="mt-2 p-4 bg-white rounded-lg shadow-lg transition-all duration-300">
                  {/* Description */}
                  <p
                    className={`text-lg mb-4 font-normal font-inter text-center ${
                      theme === "light" ? "text-dark" : "text-slate-900"
                    }`}
                  >
                    {desc}
                  </p>

                  {/* Image */}
                  {/* <img
                    className="w-full max-w-xs md:max-w-md object-cover rounded-lg shadow-md mx-auto"
                    src={img}
                    alt={value}
                  /> */}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccordionComponent;
