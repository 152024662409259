import React from "react";
import { FooterData1 } from "../../DataForPage/dummyData";
import { FooterData2 } from "../../DataForPage/dummyData";
import { FooterData3 } from "../../DataForPage/dummyData";
import logo from "../../assets/images/logo.svg";
import googlePlay from "../../assets/images/google-play.svg";
import appleStore from "../../assets/images/apple-store.svg";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div>
 
      <div className="flex items-center justify-center">
        <hr className="sm:w-full h-px w-4/5 bg-gray-400 opacity-50 outline-none border-none" />
      </div>
      <div className="flex justify-around items-center py-6">
      
        <div>
          <p className="sm:text-xs text-dark pb-2 font-inter font-light cursor-pointer no-underline align-middle tracking-wide normal-case">
            Copyright {year} 
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
