import React, { useContext } from "react";
import Lieferando from "../../assets/images/Lieferando.png";
import menu from "../../assets/images/menu.jpeg";
import flowLightPDF from "../../assets/images/JK's Burger Menu.pdf";
import flowDarkPDF from "../../assets/images/JK's Burger Menu.pdf";
import { motion } from "framer-motion";
import { ThemeBgContext } from "../ContextWrapper/ThemeContext";
const MenuSection = () => {
  const { theme } = useContext(ThemeBgContext);
  return (
  //   <div className="p-8 py-16 flex items-center justify-center ">
  //   <img
  //     className=" bg-center bg-no-repeat bg-cover max-w-full max-h-full"
  //     src={theme === "light" ? flowLight : flowDark}
  //     alt="furniture flow"
  //   />
  // </div>
//   <div className="container-fluid mx-auto bg-customOrange ">
//   <div className=" p-8 py-16 flex items-center justify-center">
//   {/* <iframe
//     className="bg-center bg-no-repeat bg-cover max-w-full max-h-full"
//     src={theme === "light" ? flowLightPDF : flowDarkPDF}
//     alt="PDF document"
//     width="100%"
//     height="600px"  // Adjust height as needed
//     title="PDF Viewer"
//   /> */}
// </div>
// </div>
<div className="container-fluid mx-auto bg-customOrange font-russoOne font-bold">
  <div className="p-8 py-16 flex flex-col items-center justify-center">
    <div className="relative w-full h-96 bg-cover bg-center bg-no-repeat"
         style={{ backgroundImage: `url(${menu})` }}>
      <div className="absolute inset-0 bg-black bg-opacity-60"></div>

      <div className="relative z-10 flex flex-col items-center justify-center h-full p-4">
        <h1 className="text-3xl font-russoOne font-bold text-white mb-4">
          Check Out Our Menu
        </h1>
        <p className="text-lg text-white">
          Discover our delicious offerings and enjoy your meal!
        </p>
        <div className="p-4 flex flex-col items-center justify-center" >
      
       <a target="_blank"
          href="https://drive.google.com/file/d/11qgheA2J4m2A8cEn0iILsRWpIQlxuQdO/view?usp=sharing"  // Link to the Lieferando website
          className="block mt-4 text-white bg-customOrange p-4  rounded-lg"
        >
          SEE MENU
        </a>
      </div>
       
      </div>
    </div>
  </div>
</div>



  );
};

export default MenuSection;
