import React, { useContext } from "react";
import header1 from "../../assets/images/burger.png";
import { Switch } from "@material-tailwind/react";
import { ThemeBgContext } from "../ContextWrapper/ThemeContext";
import logo from "../../assets/images/logojk.png";
import phone from "../../assets/images/phone.svg";
import pin from "../../assets/images/pin.svg";
const Header = ({ topRef }) => {
  const { theme, handleTheme } = useContext(ThemeBgContext);
  const switchClassName = theme === "light" 
  ? "bg-gray-200 dark:bg-gray-600"  // Light theme background for the switch
  : "bg-gray-800 dark:bg-gray-300"; // Dark theme background for the switch

 {/* <div className="pb-20">
            <button className="pushable mt-4">
              <span className="front">Start now</span>
            </button>
          </div> */}
  return (
    <div ref={topRef} className="container mx-auto pt-8">
             {/* <div className="m-4">
        <Switch   ripple={false}
         className="h-full w-full checked:bg-[#000]"
          label={theme === "light" ? "Switch to Dark" : "Switch to Light"}
          onClick={handleTheme}
        />
       
      </div> */}
      <div className="flex flex-col items-center text-center mb-4">
  <img
    className="w-72 sm:w-40 md:w-56 lg:w-64 xl:w-72"
    src={logo}
    alt="logo"
  />
</div>
  
    <div className="sm:grid-cols-1 sm:pt-16 grid grid-cols-2 justify-items-center items-center">
      <div className="sm:w-full sm:pl-4 w-full flex flex-col items-center text-center">
        <h1
          className={
            theme === "light"
              ? "sm:text-5xl text-5xl font-russonOne font-bold no-underline align-middle tracking-wide normal-case leading-normal text-dark"
              : "sm:text-5xl text-5xl font-russonOne font-bold no-underline align-middle tracking-wide normal-case leading-normal text-white"
          }
        >
          <span
            className={
              theme === "light"
                ? "sm:text-4xl text-dark text-4xl font-petitFormal font-bold"
                : "sm:text-4xl text-white text-4xl font-petitFormal font-bold"
            }
          >
            JK's Burger
          </span>{" "}
        </h1>
        <div className="mt-4 flex flex-col items-center text-center">
  <p
    className={
      theme === "light"
        ? "sm:text-lg w-4/5 font-russoOne font-bold no-underline align-middle tracking-wide normal-case text-dark text-2xl"
        : "sm:text-lg w-4/5 font-russoOne font-bold no-underline align-middle tracking-wide normal-case text-white text-2xl"
    }
  >
    Get ready to sink your teeth into burger bliss! JK's Burger is coming soon, with juicy 100% Halal beef, fresh-baked buns, and all the mouthwatering magic you can handle
  </p>
  <p
    className={
      theme === "light"
        ? "sm:text-lg w-4/5 font-russoOne font-bold no-underline align-middle tracking-wide normal-case text-dark text-2xl"
        : "sm:text-lg w-4/5 font-russoOne font-bold no-underline align-middle tracking-wide normal-case text-white text-2xl"
    }
  >
    Hold onto your taste buds, burger heaven is about to land!
  </p>

 
  <div className="mt-8 w-4/5 flex flex-col items-center">
 
    <div className="flex items-center mb-4">
    
  
            <img
              className="h-8 cursor-pointer"
              src={phone}
              alt="phone"
            ></img>
         
      <p
        className={
          theme === "light"
            ? "font-russoOne font-bold text-dark text-lg"
            : "font-russoOne font-bold text-white text-lg"
        }
      >
        +49 176 62115390
      </p>
    </div>

    
    <div className="flex items-center">
    <img
              className="h-8 cursor-pointer"
              src={pin}
              alt="pin"
            ></img>
      <p
        className={
          theme === "light"
            ? "font-russoOne font-bold text-dark text-lg"
            : "font-russoOne font-bold text-white text-lg"
        }
      >
        Eckisstraße 27 - 72574 Bad Urach
      </p>
    </div>
  </div>
</div>

        {/* <div className="pb-20">
          <button className="pushable mt-4">
            <span className="front">Start now</span>
          </button>
        </div> */}
      </div>
      <div className="sm:pl-4 mt-4">
        <img
          className="sm:h-[475px] h-[765px] w-full bg-no-repeat bg-center relative z-10 header rounded-xl"
          src={header1}
          alt="header-phone"
        />
      </div>
    </div>
  </div>
  
  );
};

export default Header;
