import chair from "../assets/images/chairPurple.png";
import sofa2 from "../assets/images/roseChair.png";
import sofa3 from "../assets/images/gray-chair.png";
import phone1 from "../assets/images/bedRoom.png";
import blueSofa from "../assets/images/blueSofa.png";
import bg1 from "../assets/images/bg1.png";
import bg2 from "../assets/images/bg2.png";
import bg3 from "../assets/images/bg3.png";
import bg4 from "../assets/images/bg4.png";
import bg5 from "../assets/images/bg5.png";

export const navbarData = [
  {
    id: "1",
    name: "News",
    link: "news",
  },
  {
    id: "2",
    name: "Company",
    link: "company",
  },
  {
    id: "3",
    name: "Features",
    link: "features",
  },
  {
    id: "4",
    name: "Room",
    link: "room",
  },
  {
    id: "5",
    name: "Demo",
    link: "demo",
  },
  {
    id: "6",
    name: "Login",
    link: "/",
  },
];
export const FooterData1 = [
  {
    id: "1",
    text: "Modern",
  },
  {
    id: "2",
    text: "Decorations",
  },
  {
    id: "3",
    text: "Events",
  },
  {
    id: "4",
    text: "Careere",
  },
  {
    id: "5",
    text: "Forum",
  },
  {
    id: "6",
    text: "Contacts",
  },
];
export const FooterData2 = [
  {
    id: "1",
    text: "Privacy policy",
    path: "Resources",
  },
  {
    id: "2",
    text: "Terms of use",
  },
  {
    id: "3",
    text: "Cookies",
  },
];
export const FooterData3 = [
  {
    id: "1",
    text: "Support",
  },
  {
    id: "2",
    text: "Customer support",
  },
  {
    id: "3",
    text: "Media",
  },
  {
    id: "4",
    text: "Marketing",
  },
];

export const sliderData = [
  {
    id: "1",
    title: "Room 1",
    text: "With AxeL, your room will shine bright again. Let furniture find there place in new beautifule warm room.",
    type: "Room1",
    img: sofa2,
  },
  {
    id: "2",
    title: "Room 2",
    text: "AxeL is your best firend for interior design, download the app and feel free to explore beautifule modern and premium designs.",
    type: "Room2",
    img: chair,
  },
  {
    id: "3",
    title: "Room 3",
    text: "AxeL is your best firend for interior design, download the app and feel free to explore beautifule modern and premium designs.",
    type: "Room3",
    img: sofa3,
  },
];

// export const tabsData = [
//   {
//     id: "0",
//     label: "Classic",
//     value: "classic",
//     desc: "Experience the timeless taste of our Classic Burger with a perfectly grilled Angus beef patty, melted cheddar cheese, and fresh toppings, all in a toasted brioche bun.",
//     img: bg1, // replace with actual image import
//     linkText: "See more",
//   },
//   {
//     id: "1",
//     label: "BBQ Bacon",
//     value: "bbq-bacon",
//     desc: "Savor the rich flavors of our BBQ Bacon Burger, featuring crispy bacon, tangy barbecue sauce, and melted cheddar cheese on a juicy Angus patty, served on a toasted brioche bun.",
//     img: bg2, // replace with actual image import
//     linkText: "See more",
//   },
//   {
//     id: "2",
//     label: "Spicy Jalapeño",
//     value: "spicy-jalapeno",
//     desc: "Turn up the heat with our Spicy Jalapeño Burger, packed with jalapeños, pepper jack cheese, and a zesty chipotle mayo, all layered on a perfectly grilled Angus beef patty.",
//     img: bg3, // replace with actual image import
//     linkText: "See more",
//   },
//   {
//     id: "3",
//     label: "Mushroom Swiss",
//     value: "mushroom-swiss",
//     desc: "Delight in the earthy flavors of our Mushroom Swiss Burger, featuring sautéed mushrooms, Swiss cheese, and a savory Angus beef patty, all nestled in a toasted brioche bun.",
//     img: bg4, // replace with actual image import
//     linkText: "See more",
//   },
//   {
//     id: "4",
//     label: "Veggie",
//     value: "veggie",
//     desc: "Enjoy a delicious meat-free option with our Veggie Burger, made with a flavorful plant-based patty, crisp lettuce, ripe tomato, and a tasty vegan aioli.",
//     img: bg5, // replace with actual image import
//     linkText: "See more",
//   },
// ];
export const tabsData = [
  // Burger Category
  {
    id: "0",
    category: "Burgers",
    label: "Old Hamburger",
    value: "old-hamburger",
    desc: "160g beef patty, grilled onions, and homemade burger sauce on a brioche toast.",
    price: "8.49",
    img: bg1, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "1",
    category: "Burgers",
    label: "Hamburger",
    value: "hamburger",
    desc: "160g beef patty, lettuce, fresh red onions, pickles, tomatoes, and homemade burger sauce.",
    price: "9.49",
    img: bg2, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "2",
    category: "Burgers",
    label: "Cheeseburger",
    value: "cheeseburger",
    desc: "160g beef patty, cheddar, lettuce, fresh red onions, pickles, tomatoes, and homemade burger sauce.",
    price: "9.99",
    img: bg3, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "3",
    category: "Burgers",
    label: "Chili-Cheeseburger",
    value: "chili-cheeseburger",
    desc: "160g beef patty, jalapeños, cheddar, lettuce, fresh red onions, pickles, tomatoes, hot sauce, and homemade burger sauce.",
    price: "10.49",
    img: bg4, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "4",
    category: "Burgers",
    label: "Crispy Chicken Burger",
    value: "crispy-chicken-burger",
    desc: "135g crunchy chicken patty, cheddar, lettuce, fresh red onions, pickles, tomatoes, and homemade garlic sauce.",
    price: "9.99",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "5",
    category: "Burgers",
    label: "Smash Burger",
    value: "smash-burger",
    desc: "80g + 80g beef patties, cheddar, lettuce, fresh red onions, pickles, tomatoes, and homemade burger sauce.",
    price: "10.99",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "6",
    category: "Burgers",
    label: "Egg Burger",
    value: "egg-burger",
    desc: "160g beef patty, double cheddar, egg, lettuce, fresh red onions, pickles, tomatoes, and homemade burger sauce.",
    price: "11.99",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "7",
    category: "Burgers",
    label: "JK's Burger",
    value: "jks-burger",
    desc: "320g beef patty, double cheddar, egg, lettuce, fresh red onions, jalapeños, pickles, tomatoes, and homemade burger sauce.",
    price: "14.99",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },

  // Veggie / Vegan Category
  {
    id: "8",
    category: "Veggie/Vegan",
    label: "Pommes Burger",
    value: "pommes-burger",
    desc: "Crispy fries, double cheddar, lettuce, fresh red onions, pickles, tomatoes, and homemade burger sauce.",
    price: "7.49",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "9",
    category: "Veggie/Vegan",
    label: "Veggie Burger",
    value: "veggie-burger",
    desc: "Vegetable patty, cheddar, lettuce, fresh red onions, pickles, tomatoes, and vegan mayonnaise.",
    price: "8.99",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "10",
    category: "Veggie/Vegan",
    label: "Vegan Chicken Burger",
    value: "vegan-chicken-burger",
    desc: "Vegan chicken patty, lettuce, fresh red onions, pickles, tomatoes, and vegan mayonnaise.",
    price: "9.99",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "11",
    category: "Veggie/Vegan",
    label: "Mac 'n' Cheese Burger",
    value: "mac-n-cheese-burger",
    desc: "Mac 'n' Cheese patty, lettuce, fresh red onions, pickles, tomatoes, and spicy sauce.",
    price: "9.99",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },

  // Fried Chicken Category
  {
    id: "12",
    category: "Fried Chicken",
    label: "JK's Bucket Menu (L)",
    value: "jks-bucket-l",
    desc: "5 crispy fried chicken pieces, served with homemade fries, a dip of your choice, and a refreshing drink.",
    price: "14.99",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "13",
    category: "Fried Chicken",
    label: "JK's Bucket Menu (XL)",
    value: "jks-bucket-xl",
    desc: "10 crispy fried chicken pieces, served with homemade fries, 2 dips of your choice, and 2 refreshing drinks.",
    price: "27.99",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },

  // Sides Category
  {
    id: "14",
    category: "Sides",
    label: "Homemade Fries",
    value: "homemade-fries",
    desc: "Delicious homemade fries, crispy and perfectly seasoned.",
    price: "3.49",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "15",
    category: "Sides",
    label: "Signature Fries",
    value: "signature-fries",
    desc: "Belgian-style signature fries, crispy and delicious.",
    price: "4.99",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "16",
    category: "Sides",
    label: "Onion Rings",
    value: "onion-rings",
    desc: "6 pieces of crispy onion rings.",
    price: "5.49",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "17",
    category: "Sides",
    label: "Mozzarella Sticks",
    value: "mozzarella-sticks",
    desc: "6 pieces of crunchy mozzarella sticks.",
    price: "5.49",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "18",
    category: "Sides",
    label: "Chili Cheese Bites",
    value: "chili-cheese-bites",
    desc: "6 pieces of spicy chili cheese bites.",
    price: "4.49",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },
  {
    id: "19",
    category: "Sides",
    label: "Chicken Fingers",
    value: "chicken-fingers",
    desc: "6 crispy chicken fingers, perfect for dipping.",
    price: "5.49",
    img: bg5, // replace with actual image import
    linkText: "See more",
  },
];

// export const tabsData = [
//   {
//     id: "0",
//     label: "Bedroom",
//     value: "bedroom",
//     desc: `AxeL is your best firend for interior design, download the app and feel free to explore beautifule modern and premium designs.`,
//     img: phone1,
//     linkText: "See more",
//   },
//   {
//     id: "1",
//     label: "LivingRoom",
//     value: "livingroom",
//     desc: "AxeL is your best firend for interior design, download the app and feel free to explore beautifule modern and premium designs.",
//     img: phone1,
//     linkText: "See more",
//   },

//   {
//     id: "2",
//     label: "Kitchen",
//     value: "kitchen",
//     desc: `AxeL is your best firend for interior design, download the app and feel free to explore beautifule modern and premium designs.`,
//     img: phone1,
//     linkText: "See more",
//   },

//   {
//     id: "3",
//     label: "Office",
//     value: "office",
//     desc: ` AxeL is your best firend for interior design, download the app and feel free to explore beautifule modern and premium designs.`,
//     img: phone1,
//     linkText: "See more",
//   },

//   {
//     id: "4",
//     label: "KidsRoom",
//     value: "kidsroom",
//     desc: `AxeL is your best firend for interior design, download the app and feel free to explore beautifule modern and premium designs.`,
//     img: phone1,
//     linkText: "See more",
//   },
// ];
export const cardData = [
  {
    id: "0",
    title: "Ready to get started?",
    text: "Explore AxeL Pricing and chose the plan that suites you the best, or send as an inquery about invidual plan. You can also request a demo app to test in a secure enviroment.",
  },
  {
    id: "1",
    title: "Room",
    text: "Customize your own Bedroom, LivingRoom, Kitchen, Office and much more. Anytime, in a matter of seconds.",
  },
  {
    id: "2",
    title: "Connect to AxeL",
    text: "Setup your room on AxeL in a few seconds. All you need is few images and creative ideas and your business is ready.",
  },
];

export const room = [
  {
    id: "1",
    name: "Bedroom ideas for you and your partner. ",
    img: blueSofa,
  },
  {
    id: "2",
    name: "No room is quite as warm as the living room.",
    img: blueSofa,
  },
  {
    id: "3",
    name: "No room is quite as multifunctional as the kitchen.",
    img: blueSofa,
  },
];
